import axios from "axios";
import { BASE_URL } from "./config";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const createProject = async (projectData) => {
  try {
    const response = await api.post("/projects/", projectData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteProject = async (project_id) => {
  try {
    const response = await api.delete(`/projects/${project_id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateProject = async (project_id, projectData) => {
  try {
    const response = await api.put(`/projects/${project_id}`, projectData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjects = async () => {
  try {
    const response = await api.get("/projects/", {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProject = async (project_id) => {
  try {
    const response = await api.get(`/projects/${project_id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const authVoterToEvent = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/auth`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEventColors = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/colors`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectColors = async (project_id) => {
  try {
    const response = await api.get(`/projects/${project_id}/colors`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadProjectImage = async (project_id, file, type) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await api.post(
      `/projects/${project_id}/upload/${type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadEventQrCode = async (event_id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await api.post(
      `/events/${event_id}/voter_qrcode`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const createEvent = async (event) => {
  try {
    const response = await api.post("/events/", event, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEvents = async () => {
  try {
    const response = await api.get("/events/", {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEvent = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getEventLogo = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/logo`);
    return response; // You may need to handle the response differently if it's an image
  } catch (error) {
    if (error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const updateEvent = async (event_id, event) => {
  try {
    const response = await api.put(`/events/${event_id}`, event, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteEvent = async (event_id) => {
  try {
    const response = await api.delete(`/events/${event_id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEventByUrl = async (unique_str) => {
  try {
    const response = await api.get(`/events/by-url/${unique_str}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadStatements = async (project_id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await api.post(
      `/projects/${project_id}/statements/upload_csv/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNbVotesStatement = async (event_id, statement_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/statements/${statement_id}/votes/`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStatements = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/statements/`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStatement = async (event_id, statement_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/statements/${statement_id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCurrentStatement = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/current_statement`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const deleteStatement = async (event_id, statement_id) => {
  try {
    const response = await api.delete(
      `/events/${event_id}/statements/${statement_id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getVoteStats = async (event_id, statement_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/statements/${statement_id}/vote_stats`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createVoter = async (event_id, voter) => {
  try {
    const response = await api.post(`/events/${event_id}/voters`, voter);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getVoter = async (event_id) => {
  // Get the token from local storage

  try {
    const response = await api.get(`/events/${event_id}/voter`, {
      headers: {
        // Include the token in the Authorization header
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEventVoters = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/voters`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportVoters = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/export_links`, {
      responseType: "blob", // Important for handling the binary data
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    // Create a URL for the blob
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor to trigger download
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", `event_${event_id}_links.xlsx`); // Set the file name
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove(); // Clean up
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const generateVoters = async (event_id, number_of_voters) => {
  try {
    const response = await api.post(
      `/events/${event_id}/generate_voters`,
      { number: number_of_voters },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const authVoter = async (event_id, username, link) => {
  const data = {
    username: username,
    event_id: event_id,
    link: link,
  };
  try {
    const response = await api.post(`/voters/token`, data);

    // Assuming the token is in the 'access_token' field of the response
    const token = response.data.access_token;

    // Save the token in localStorage
    Cookies.set("token", token, {
      expires: 1,
      path: "/",
      sameSite: "strict",
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createVote = async (event_id, statement_id, voteData) => {
  try {
    const response = await api.post(
      `/events/${event_id}/statements/${statement_id}/vote`,
      voteData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getVote = async (event_id, statement_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/statements/${statement_id}/vote`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const authAdmin = async (password) => {
  const data = {
    password: password,
  };
  try {
    const response = await api.post(`/admin/token`, data);

    // Assuming the token is in the 'access_token' field of the response
    const token = response.data.access_token;

    // Save the token in localStorage
    Cookies.set("token", token, {
      expires: 1,
      path: "/",
      sameSite: "strict",
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportVotes = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/export-votes`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      responseType: "blob", // This is important if you expect a file download response
    });

    // Depending on how you want to handle the downloaded Excel file,
    // you might need to create a Blob and a download link.
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `event_votes_${event_id}.xlsx`);
    document.body.appendChild(link);
    link.click();

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportVotesProject = async (project_id) => {
  try {
    const response = await api.get(`/projects/${project_id}/export-votes`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      responseType: "blob", // This is important if you expect a file download response
    });

    // Depending on how you want to handle the downloaded Excel file,
    // you might need to create a Blob and a download link.
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `event_votes_${project_id}.xlsx`);
    document.body.appendChild(link);
    link.click();

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const authPresenter = async (event_id, password) => {
  const data = {
    event_id: event_id,
    password: password,
  };
  try {
    const response = await api.post(`/presenter/token`, data);
    const token = response.data.access_token;

    // Assuming the token is in the 'access_token' field of the response
    Cookies.set("token", token, { expires: 1, path: "/", sameSite: "strict" });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const isPresenterAuth = async () => {
  try {
    const response = await api.post(`/presenter/is_auth`, null, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const isAdminAuth = async () => {
  try {
    const response = await api.post(`/admin/is_auth`, null, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const launchEvent = async (event_id) => {
  try {
    const response = await api.post(`/event/${event_id}/launch/`, null, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const finishVotingEvent = async (event_id) => {
  try {
    const response = await api.post(`/event/${event_id}/finish_voting/`, null, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const nextStatementEvent = async (event_id, pass_session) => {
  try {
    const response = await api.post(
      `/event/${event_id}/next_statement/${pass_session}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStatementResults = async (event_id, statement_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/statements/${statement_id}/vote_stats`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectVoteStats = async (project_id, statement_id) => {
  try {
    const response = await api.get(
      `/projects/${project_id}/statements/${statement_id}/vote_stats`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectStatements = async (project_id) => {
  try {
    const response = await api.get(`/projects/${project_id}/statements/`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectStatement = async (project_id, statement_id) => {
  try {
    const response = await api.get(
      `/projects/${project_id}/statements/${statement_id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEventStatements = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/statements/`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCurrentStatementForVoter = async (event_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/current_statement_voter/`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNextStatementForVoter = async (event_id) => {
  try {
    const response = await api.get(
      `/events/${event_id}/next_statement_voter/`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQuestions = async (project_id) => {
  try {
    const response = await api.get(`/projects/${project_id}/questions`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQuestionsEvent = async (event_id) => {
  try {
    const response = await api.get(`/events/${event_id}/questions`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createQuestion = async (project_id, question) => {
  try {
    const response = await api.post(
      `/projects/${project_id}/questions`,
      question,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteQuestion = async (project_id, question_id) => {
  try {
    const response = await api.delete(
      `/projects/${project_id}/questions/${question_id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateQuestion = async (project_id, question_id, question) => {
  try {
    const response = await api.put(
      `/projects/${project_id}/questions/${question_id}`,
      question,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAnswer = async (question_id, answer) => {
  try {
    const response = await api.post(
      `/questions/${question_id}/answers`,
      answer,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStatementByOrder = async (event_id, order_number) => {
  try {
    const response = await api.get(
      `/events/${event_id}/statements/order/${order_number}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserStatus = async (event_id, status) => {
  try {
    const response = await api.put(
      `/events/${event_id}/voters/status`,
      { event_status: status },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCurrentStatementNumber = async (
  event_id,
  current_statement_number
) => {
  try {
    const response = await api.put(
      `/events/${event_id}/voters/current_statement_number`,
      { current_statement_number },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getVoterByLink = async (event_id, link) => {
  try {
    const response = await api.get(`/events/${event_id}/voters/${link}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateQuestionOrder = async (project_id, question_id, order) => {
  try {
    const response = await api.put(
      `/projects/${project_id}/questions/${question_id}/order`,
      { order },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
