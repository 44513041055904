import React, { Component } from 'react';
import { ThemeContext } from './ThemeContext';
import { withRouter } from './withRouter';

class ThemeProvider extends Component {
  state = {
    colors: {
      primary: '#203364',
      secondary: '#F49D21',
      success: '#28a745',
      info: '#8E5CA1',
      warning: '#ffc107',
      danger: '#dc3545',
      light: '#f8f9fa',
      dark: '#343a40',
      bg: '#FDEDE9',
    },
  };

  async componentDidMount() {
    console.log(this.props.params.unique_str);
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state.colors}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export default withRouter(ThemeProvider);
