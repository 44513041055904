import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { withRouter } from "../withRouter";
import {
  createProject,
  deleteProject,
  exportVotesProject,
  getProject,
  updateProject,
  uploadProjectImage,
  uploadStatements,
} from "../api";
import AdminNavBar from "./AdminNavBar";
import LoadingButton from "./LoadingButton";
import {
  CardHeading,
  InfoCircle,
  CardImage,
  QuestionCircle,
  Download,
  BarChart,
  Palette,
} from "react-bootstrap-icons";
import { BASE_URL } from "../config";
import Questions from "./Questions";

class ModifyProject extends Component {
  state = {
    name: "",

    logo_file: null,
    voter_footer_image_file: null,
    presenter_background_image_file: null,

    statements: [],
    isUploadingStatements: false,
    isUploadingLogo: false,
    showInformationAlert: "",
    showLogoUploadAlert: "",
    showStatementUploadAlert: "",
    showColorsAlert: "",
    backgroundColor: "#FFFFFF",
    headerBackgroundColor: "#FFFFFF",
    statementBackgroundColor: "#FFFFFF",
    voterFooterImage: null,
    presenterBackgroundImage: null,
    logoUploadTimestamp: Date.now(),
    voterFotterUploadTimestamp: Date.now(),
    presenterBackgroundUploadTimestamp: Date.now(),
  };

  async componentDidMount() {
    if (this.props.params.project_id !== "new") {
      const project = await getProject(this.props.params.project_id);
      this.setState({
        name: project.name,
        backgroundColor: project.background_color,
        headerBackgroundColor: project.header_background_color,
        statementBackgroundColor: project.statement_background_color,

        voter_footer_image_url: project.voter_footer_image_url,
        presenter_background_image_url: project.presenter_background_image_url,

        logo_url: project.logo_url,

        statements: project.statements,
      });
    }
  }

  handleDelete = async () => {
    try {
      await deleteProject(this.props.params.project_id);
      this.props.navigate(`/admin`);
    } catch (err) {
      this.handleError(err);
    }
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;

    if (target.type === "file") {
      // Handle file input
      const file = target.files[0]; // Get the selected file
      this.setState({ [name]: file }); // You can set the file object to your state
    } else {
      // Handle other input types
      const value = target.value;
      this.setState({ [name]: value });
    }
  };

  handleSubmit = async () => {
    let project;
    if (this.props.params.project_id === "new") {
      project = await createProject({
        name: this.state.name,
      });

      this.props.navigate(`/admin/project/${project.id}`);
    } else {
      try {
        project = await updateProject(this.props.params.project_id, {
          name: this.state.name,
        });
      } catch (err) {
        this.setState({
          showInformationAlert: "danger",

          error: err.response.data.detail,
        });
      }
    }
    this.setState({
      ...project,
      showInformationAlert: "success",
    });
  };

  handleFieldUpdate = async (field, value) => {
    let project;

    try {
      project = await updateProject(this.props.params.project_id, {
        [field]: value,
      });
    } catch (err) {
      this.setState({
        showFieldUpdateAlert: "danger",
        error: err.response.data.detail,
      });
    }

    this.setState({
      ...project,
      showFieldUpdateAlert: "success",
    });
  };

  handleColors = async () => {
    let project;

    try {
      project = await updateProject(this.props.params.project_id, {
        background_color: this.state.backgroundColor,
        header_background_color: this.state.headerBackgroundColor,
        statement_background_color: this.state.statementBackgroundColor,
      });
    } catch (err) {
      this.setState({
        showColorsAlert: "danger",

        error: err.response.data.detail,
      });
    }

    this.setState({
      ...project,
      showColorsAlert: "success",
    });
  };

  handleStatementsUpload = async () => {
    if (this.state.statement_file) {
      this.setState({ isUploadingStatements: true });
      try {
        const statements = await uploadStatements(
          this.props.params.project_id,
          this.state.statement_file
        );
        this.setState({
          statements,
          isUploadingStatements: false,
          showStatementUploadAlert: "success",
          statement_file: null,
        });
      } catch (err) {
        this.setState({
          isUploadingStatements: false,

          showStatementUploadAlert: "danger",
          error: err.response.data.detail,
        });
      }
    }
  };

  handleLogoUpload = async () => {
    if (this.state.logo_file) {
      this.setState({ isUploadingLogo: true });
      try {
        const project = await uploadProjectImage(
          this.props.params.project_id,
          this.state.logo_file,
          "logo"
        );
        this.setState({
          logo_url: project.logo_url,
          showLogoUploadAlert: "success",
          logoUploadTimestamp: Date.now(), // Add this line

          logo_file: null,
        });
      } catch (err) {
        this.setState({
          showLogoUploadAlert: "danger",
          error: err.response.data.detail,
        });
      }
    }
  };

  handleVoterFooterImageUpload = async () => {
    if (this.state.voter_footer_image_file) {
      this.setState({ isUploadingLogo: true });
      try {
        const project = await uploadProjectImage(
          this.props.params.project_id,
          this.state.voter_footer_image_file,
          "voter_footer"
        );
        this.setState({
          voter_footer_image_url: project.voter_footer_image_url,
          voterFotterUploadTimestamp: Date.now(),
          showLogoUploadAlert: "success",
          voter_footer_image_file: null,
        });
      } catch (err) {
        this.setState({
          showLogoUploadAlert: "danger",
          error: err.response.data.detail,
        });
      }
    }
  };

  handlePresenterBackgroundImageUpload = async () => {
    if (this.state.presenter_background_image_file) {
      this.setState({ isUploadingLogo: true });
      try {
        const project = await uploadProjectImage(
          this.props.params.project_id,
          this.state.presenter_background_image_file,
          "presenter_background"
        );
        this.setState({
          presenter_background_image_url:
            project.presenter_background_image_url,
          showLogoUploadAlert: "success",
          presenter_background_image_file: null,
          presenterBackgroundUploadTimestamp: Date.now(),
        });
      } catch (err) {
        this.setState({
          showLogoUploadAlert: "danger",
          error: err.response.data.detail,
        });
      }
    }
  };

  async exportVotes() {
    try {
      await exportVotesProject(this.props.params.project_id);
    } catch (err) {
      this.handleError(err);
    }
  }
  render() {
    return (
      <div style={{ backgroundColor: "#F6F7F9", minHeight: "100vh" }}>
        <AdminNavBar />
        <Container fluid className="px-5 pt-3 pb-5">
          <div
            className="border-bottom mb-3"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CardHeading size={26}></CardHeading>
              <h2 className="ms-3">Project</h2>
            </div>
          </div>
          <div
            className="border-bottom mb-3"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoCircle size={26}></InfoCircle>
              <h3 className="ms-3">Information</h3>
            </div>
          </div>{" "}
          <Alert
            show={this.state.showInformationAlert !== ""}
            variant={this.state.showInformationAlert}
          >
            <Alert.Heading>
              {this.state.showInformationAlert === "success"}
            </Alert.Heading>
            <p>
              {this.state.showInformationAlert === "success"
                ? "Information successfully updated."
                : "Error when updating information : " + this.state.error}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => this.setState({ showInformationAlert: "" })}
                variant={"outline-" + this.state.showInformationAlert}
              >
                Close
              </Button>
            </div>
          </Alert>
          <Form>
            <Row xs={1} md={3} className="mb-3">
              <Col>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>

            <LoadingButton
              onClick={this.handleSubmit.bind(this)}
              variant="primary"
            >
              {this.props.params.project_id === "new"
                ? "Create project"
                : "Save information"}
            </LoadingButton>
          </Form>
          {this.props.params.project_id !== "new" && (
            <>
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CardImage size={26}></CardImage>
                  <h3 className="ms-3">Images</h3>
                </div>
              </div>{" "}
              <Alert
                show={this.state.showLogoUploadAlert !== ""}
                variant={this.state.showLogoUploadAlert}
              >
                <Alert.Heading>
                  {this.state.showLogoUploadAlert === "success"}
                </Alert.Heading>
                <p>
                  {this.state.showLogoUploadAlert === "success"
                    ? "Logo successfully uploaded."
                    : "Error when uploading logo : " + this.state.error}
                </p>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => this.setState({ showLogoUploadAlert: "" })}
                    variant={"outline-" + this.state.showLogoUploadAlert}
                  >
                    Close
                  </Button>
                </div>
              </Alert>
              <Form className={"mb-3"}>
                <Row xs={1} md={3} className="mb-3">
                  <Col>
                    <Form.Label>Project Logo</Form.Label>
                    <Form.Control
                      required
                      onChange={this.handleChange}
                      name="logo_file"
                      type="file"
                    />
                  </Col>
                  {this.state.logo_url && (
                    <Col>
                      <img
                        src={
                          BASE_URL +
                          "/projects/" +
                          this.props.params.project_id +
                          "/data/logo" +
                          "?t=" +
                          this.state.logoUploadTimestamp // Add this line
                        }
                        style={{
                          width: "100%",
                          height: 100,
                          resizeMode: "contain",
                        }}
                      />
                    </Col>
                  )}

                  <Col>
                    <p>
                      <b>The format of the logo must be as follows: </b>
                      <br />A png or jpg file of size 100x300.
                    </p>
                  </Col>
                </Row>
                <LoadingButton
                  className="me-2"
                  onClick={this.handleLogoUpload.bind(this)}
                  disabled={!this.state.logo_file}
                  variant="primary"
                >
                  Upload
                </LoadingButton>
                {this.state.project?.logo_url != "" && (
                  <LoadingButton
                    onClick={this.handleFieldUpdate.bind(this, "logo_url", "")}
                    variant="danger"
                  >
                    Delete
                  </LoadingButton>
                )}
              </Form>
              <Form className={"mb-3"}>
                <Row xs={1} md={3} className="mb-3">
                  <Col>
                    <Form.Label>Voter Footer Image</Form.Label>
                    <Form.Control
                      required
                      onChange={this.handleChange}
                      name="voter_footer_image_file"
                      type="file"
                    />
                  </Col>
                  {this.state.voter_footer_image_url && (
                    <Col>
                      <img
                        src={
                          BASE_URL +
                          "/projects/" +
                          this.props.params.project_id +
                          "/data/voter_footer" +
                          "?t=" +
                          this.state.voterFotterUploadTimestamp
                        }
                        style={{
                          width: "100%",
                          height: 100,
                          resizeMode: "contain",
                        }}
                      />
                    </Col>
                  )}

                  <Col>
                    <p>
                      <b>
                        The format of the voter footer image must be as follows:{" "}
                      </b>
                      <br />A png or svg file of size 1920x54.
                    </p>
                  </Col>
                </Row>
                <LoadingButton
                  className="me-2"
                  onClick={this.handleVoterFooterImageUpload}
                  disabled={!this.state.voter_footer_image_file}
                  variant="primary"
                >
                  Upload
                </LoadingButton>
                {this.state.project?.voter_footer_image_url != "" && (
                  <LoadingButton
                    onClick={this.handleFieldUpdate.bind(
                      this,
                      "voter_footer_image_url",
                      ""
                    )}
                    variant="danger"
                  >
                    Delete
                  </LoadingButton>
                )}
              </Form>
              <Form className={"mb-3"}>
                <Row xs={1} md={3} className="mb-3">
                  <Col>
                    <Form.Label>Presenter Background Image</Form.Label>
                    <Form.Control
                      required
                      onChange={this.handleChange}
                      name="presenter_background_image_file"
                      type="file"
                    />
                  </Col>
                  {this.state.presenter_background_image_url && (
                    <Col>
                      <img
                        src={
                          BASE_URL +
                          "/projects/" +
                          this.props.params.project_id +
                          "/data/presenter_background" +
                          "?t=" +
                          this.state.presenterBackgroundUploadTimestamp
                        }
                        style={{
                          width: "100%",
                          height: 100,
                          resizeMode: "contain",
                        }}
                      />
                    </Col>
                  )}

                  <Col>
                    <p>
                      <b>
                        The format of the presenter background image must be as
                        follows:{" "}
                      </b>
                      <br />A png or jpg file of size 160x700.
                    </p>
                  </Col>
                </Row>
                <LoadingButton
                  className="me-2"
                  onClick={this.handlePresenterBackgroundImageUpload.bind(this)}
                  disabled={!this.state.presenter_background_image_file}
                  variant="primary"
                >
                  Upload
                </LoadingButton>
                {this.state.project?.presenter_background_image_url != "" && (
                  <LoadingButton
                    onClick={this.handleFieldUpdate.bind(
                      this,
                      "presenter_background_image_url",
                      ""
                    )}
                    variant="danger"
                  >
                    Delete
                  </LoadingButton>
                )}
              </Form>
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <QuestionCircle size={26}></QuestionCircle>
                  <h3 className="ms-3">Statements</h3>
                </div>
              </div>{" "}
              <Alert
                show={this.state.showStatementUploadAlert !== ""}
                variant={this.state.showStatementUploadAlert}
              >
                <Alert.Heading>
                  {this.state.showStatementUploadAlert === "success"}
                </Alert.Heading>
                <p>
                  {this.state.showStatementUploadAlert === "success"
                    ? "Statements successfully uploaded."
                    : "Error when uploading statements : " + this.state.error}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <LoadingButton
                    onClick={() =>
                      this.setState({ showStatementUploadAlert: "" })
                    }
                    variant={"outline-" + this.state.showStatementUploadAlert}
                  >
                    Close
                  </LoadingButton>
                </div>
              </Alert>
              <Form className={"mb-3"}>
                <Row xs={1} md={2} className="mb-3">
                  <Col>
                    <Form.Label>CSV File</Form.Label>
                    <Form.Control
                      required
                      onChange={this.handleChange}
                      name="statement_file"
                      type="file"
                    />
                  </Col>
                  <Col>
                    <p>
                      <b>The format of the CSV file must be as follows:</b>
                      <br />A column named "order_number" containing the
                      statement number, and a column named "statement_string"
                      containing the statement. The column delimiters must be
                      semicolon or comma.
                    </p>
                  </Col>
                </Row>
                <LoadingButton
                  onClick={this.handleStatementsUpload.bind(this)}
                  disabled={!this.state.statement_file}
                  variant="primary"
                >
                  Upload
                </LoadingButton>
              </Form>
              {this.state.statements.length > 0 && (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Statement</th>
                      <th>Session Number</th>
                      <th>Session Name</th>
                      <th>Session Subtitle</th>
                      <th>Statement Version</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.statements.map((statement, index) => (
                      <tr key={index}>
                        <td>{statement.order_number}</td>
                        <td>{statement.statement_string}</td>
                        <td>{statement.session_number}</td>
                        <td>{statement.session_name}</td>
                        <td>{statement.session_subtitle}</td>
                        <td>{statement.version}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Palette size={26}></Palette>
                  <h3 className="ms-3">Colors</h3>
                </div>
              </div>
              <Alert
                show={this.state.showColorsAlert !== ""}
                variant={this.state.showColorsAlert}
              >
                <Alert.Heading>
                  {this.state.showColorsAlert === "success"}
                </Alert.Heading>
                <p>
                  {this.state.showColorsAlert === "success"
                    ? "Colors successfully updated."
                    : "Error when updating colors : " + this.state.error}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => this.setState({ showColorsAlert: "" })}
                    variant={"outline-" + this.state.showColorsAlert}
                  >
                    Close
                  </Button>
                </div>
              </Alert>
              <Form.Group>
                <Form.Label>Background Color</Form.Label>
                <Form.Control
                  type="color"
                  name="backgroundColor"
                  value={this.state.backgroundColor}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Header Background Color</Form.Label>
                <Form.Control
                  type="color"
                  name="headerBackgroundColor"
                  value={this.state.headerBackgroundColor}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Statement Background Color</Form.Label>
                <Form.Control
                  type="color"
                  name="statementBackgroundColor"
                  value={this.state.statementBackgroundColor}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <LoadingButton
                className="mt-2"
                onClick={this.handleColors.bind(this)}
                variant="primary"
              >
                Save colors
              </LoadingButton>
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BarChart size={26}></BarChart>
                  <h3 className="ms-3">Form</h3>
                </div>
              </div>
              <Questions projectId={this.props.params.project_id}></Questions>
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BarChart size={26}></BarChart>
                  <h3 className="ms-3">Results</h3>
                </div>
              </div>
              <LoadingButton
                className="mb-3"
                onClick={() => this.exportVotes()}
              >
                <Download className="me-1"></Download>
                Export Results
              </LoadingButton>
              <LoadingButton
                className="ms-3 mb-3"
                onClick={() =>
                  this.props.navigate(
                    "/admin/project/" +
                      this.props.params.project_id +
                      "/presenter_view"
                  )
                }
              >
                <BarChart className="me-1"></BarChart>
                See graphs
              </LoadingButton>
            </>
          )}
          <br />
          {this.props.params.project_id !== "new" && (
            <LoadingButton
              variant="danger"
              onClick={this.handleDelete.bind(this)}
            >
              Delete project
            </LoadingButton>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(ModifyProject);
