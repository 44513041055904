import React from 'react';
import { ThemeContext } from './ThemeContext';

export function withTheme(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ThemeContext.Consumer>
          {(colors) => {
            const style = colors
              ? {
                  '--primary': colors.primary,
                  '--secondary': colors.secondary,
                  '--danger': colors.danger,
                  '--dark': colors.dark,
                  '--info': colors.info,
                  '--light': colors.light,
                  '--bg': colors.bg,

                  '--success': colors.success,
                  '--warning': colors.warning,
                  // Add other Bootstrap variables you want to override
                }
              : {};
            return (
              <div style={style}>
                <WrappedComponent {...this.props} />
              </div>
            );
          }}
        </ThemeContext.Consumer>
      );
    }
  };
}
