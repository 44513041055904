import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { withRouter } from '../withRouter';
import { withTheme } from '../withTheme';
import { ArrowLeftShort } from 'react-bootstrap-icons';
class PresenterNavBar extends Component {
  render() {
    return (
      <Navbar style={{ backgroundColor: this.props.backgroundColor }}>
        <Container>
          <Navbar.Brand className='fw-bold' style={{ color: 'var(--light)' }}>
            {this.props.goBack == true && (
              <Button
                variant='outline-dark me-2'
                size='sm'
                onClick={() => window.history.back()}
                style={{ color: 'var(--light)' }}
              >
                <ArrowLeftShort />
              </Button>
            )}

            {this.props.text}
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(withTheme(PresenterNavBar));
