import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { authAdmin, getProjects, isAdminAuth } from "../api";
import { withRouter } from "../withRouter";
import Badge from "react-bootstrap/esm/Badge";
import AdminNavBar from "./AdminNavBar";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import LoadingButton from "./LoadingButton";
import {
  CardHeading,
  PencilSquare,
  PlusSquare,
  EyeFill,
} from "react-bootstrap-icons";

class Admin extends Component {
  state = {
    projects: [],
    showLogin: false,
    showModal: false,
    password: "",
    showAlertPassword: "",
    error: "",
    showError: "",
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      await isAdminAuth();
      await this.loadPage();
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (
          e.response.status === 401 ||
          e.response.status === 403 ||
          e.response.status === 402 ||
          e.response.status === 400
        ) {
          // Redirect to another page if the error is 400
          this.setState({ showLogin: true, loading: false });
          return;
        }
      }
      this.setState({
        showError: true,
        error: e.response.data.detail,
      });
    }
    this.setState({ loading: false });
  }

  async loadPage() {
    const projects = await getProjects();
    this.setState({ projects });
  }
  navigateToEvent = (projectId, eventId) => {
    this.props.navigate(`/admin/project/${projectId}/event/${eventId}`);
  };

  navigateToProject = (projectId) => {
    this.props.navigate(`/admin/project/new`);
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handlePasswordSubmit = async () => {
    try {
      await authAdmin(this.state.password);
      this.loadPage();
    } catch (error) {
      this.setState({
        showAlertPassword: "danger",
        error: error.response.data.detail,
      });
      return;
    }

    this.setState({ showLogin: false });
  };

  formatDate = (date) => {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  render() {
    return (
      <div
        style={{
          backgroundColor: "#F6F7F9",
          minHeight: "100vh",
        }}
      >
        <AdminNavBar />
        <div className="px-5 py-3">
          <Alert show={this.state.showError} variant={"danger"}>
            <Alert.Heading>Error.</Alert.Heading>
            <p>{this.state.error}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <LoadingButton
                onClick={() => this.setState({ showError: false })}
                variant="outline-danger"
              >
                Close
              </LoadingButton>
            </div>
          </Alert>
          <div
            className="border-bottom mb-3"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CardHeading size={26}></CardHeading>
              <h2 className="ms-3">Projects</h2>
            </div>
            <LoadingButton
              onClick={this.navigateToProject.bind(this, null)}
              variant="primary"
            >
              <PlusSquare></PlusSquare> New project
            </LoadingButton>{" "}
          </div>
          <div style={{ width: "100%" }}>
            {this.state.loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner style={{ alignSelf: "center" }}> </Spinner>
              </div>
            ) : (
              this.state.projects
                ?.sort((a, b) => a.id - b.id)
                .map((project) => (
                  <div key={project.id} className="border-left px-3">
                    <Row
                      className="mb-2 p-3"
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                    >
                      <Col>
                        <div
                          className="border-bottom mb-3"
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{ padding: 0, margin: 0 }}>
                            {project.name}
                          </h3>
                          <LoadingButton
                            as={Link}
                            to={"/admin/project/" + project.id}
                            variant="link"
                          >
                            <PencilSquare size={20}></PencilSquare>
                          </LoadingButton>
                        </div>
                      </Col>
                      <Col className="text-end">
                        <LoadingButton
                          onClick={this.navigateToEvent.bind(
                            this,
                            project.id,
                            "new"
                          )}
                          variant="secondary"
                        >
                          <PlusSquare></PlusSquare> New event
                        </LoadingButton>
                      </Col>
                    </Row>
                    <Row xs={1} md={4} className="mt-3">
                      {project.events
                        ?.sort((a, b) => a.id - b.id)
                        .map((event) => (
                          <Col key={event.id}>
                            <Card
                              onClick={() =>
                                this.props.navigate(
                                  "/admin/project/" +
                                    project.id +
                                    "/event/" +
                                    event.id
                                )
                              }
                              className="mb-3"
                              style={{
                                borderWidth: 0,
                                boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
                                cursor: "pointer",
                              }}
                            >
                              <Card.Body>
                                <Card.Title>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>{event.name}</div>
                                    <EyeFill></EyeFill>
                                  </div>
                                </Card.Title>
                                <Card.Subtitle>
                                  {this.formatDate(event.start_date) +
                                    " - " +
                                    this.formatDate(event.end_date)}
                                </Card.Subtitle>

                                <br />

                                <Badge
                                  className="mt-3"
                                  bg={
                                    event.status === "finished"
                                      ? "success"
                                      : "warning"
                                  }
                                >
                                  {event.with_presenter ? event.status : ""}
                                </Badge>

                                {/* Other event details */}
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  </div>
                ))
            )}
          </div>
        </div>

        {this.state.showLogin ? (
          <Modal show={this.state.showLogin}>
            <Modal.Header>
              <Modal.Title>Enter admin password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert
                show={this.state.showAlertPassword === "danger"}
                variant={"danger"}
              >
                <Alert.Heading>Incorrect password</Alert.Heading>
                <p>Please retry.</p>
              </Alert>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <LoadingButton
                variant="primary"
                onClick={this.handlePasswordSubmit.bind(this)}
              >
                Submit
              </LoadingButton>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Admin);
