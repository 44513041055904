import React from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import axios from "axios";
import {
  createQuestion,
  deleteQuestion,
  getQuestions,
  updateQuestionOrder,
} from "../api";
import { TrashFill } from "react-bootstrap-icons";
class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      newQuestion: {
        question: "",
        type: "",
        order: 0,
        mandatory: true,
        options: [],
        conditions: [],
        condition_int: 0,
        condition_int_operator: "",
      },
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  fetchQuestions = async () => {
    try {
      const response = await getQuestions(this.props.projectId);
      this.setState({ questions: response.sort((a, b) => a.order - b.order) });
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  handleInputChange = (event) => {
    this.setState({
      newQuestion: {
        ...this.state.newQuestion,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleOptionsChange = (event) => {
    this.setState({
      newQuestion: {
        ...this.state.newQuestion,
        options: event.target.value.split(","),
      },
    });
  };

  handleConditionChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      newQuestion: {
        ...this.state.newQuestion,
        conditions: event.target.value.split(","),
      },
    });
  };

  deleteQuestion = async (questionId) => {
    try {
      await deleteQuestion(this.props.projectId, questionId);
      this.fetchQuestions();
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  moveQuestion = async (questionId, direction) => {
    try {
      let newOrder;
      if (direction === "up") {
        const questions = this.state.questions;
        const index = questions.findIndex((q) => q.id === questionId);

        const question = questions[index];
        if (question.order === 1) {
          return;
        }
        newOrder = question.order - 1;
      }
      if (direction === "down") {
        const questions = this.state.questions;
        const index = questions.findIndex((q) => q.id === questionId);

        const question = questions[index];
        if (question.order === questions.length) {
          return;
        }
        newOrder = question.order + 1;
      }
      const questions = await updateQuestionOrder(
        this.props.projectId,
        questionId,
        newOrder
      );
      this.setState({ questions: questions.sort((a, b) => a.order - b.order) });
    } catch (error) {
      console.error("Error moving question:", error);
    }
  };

  createQuestion = async () => {
    try {
      const maxOrder =
        this.state.questions.length === 0
          ? 0
          : Math.max(...this.state.questions.map((q) => q.order));
      this.state.newQuestion.order = maxOrder + 1;
      await createQuestion(this.props.projectId, this.state.newQuestion);
      this.setState({
        newQuestion: {
          question: "",
          type: "",
          options: [],
          conditions: [],
          condition_int: 0,
          condition_int_operator: "",
        },
      });
      this.fetchQuestions();
    } catch (error) {
      console.error("Error creating question:", error);
    }
  };

  renderConditionInput = () => {
    const { type } = this.state.newQuestion;
    switch (type) {
      case "number input":
        return (
          <>
            <Form.Group>
              <Form.Label>Condition Integer</Form.Label>
              <Form.Control
                type="number"
                name="condition_int"
                value={this.state.newQuestion.condition_int}
                onChange={(event) => {
                  this.setState({
                    newQuestion: {
                      ...this.state.newQuestion,
                      condition_int: parseInt(event.target.value),
                    },
                  });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Condition Operator</Form.Label>
              <Form.Control
                as="select"
                name="condition_int_operator"
                value={this.state.newQuestion.condition_int_operator}
                onChange={(event) => {
                  this.setState({
                    newQuestion: {
                      ...this.state.newQuestion,
                      condition_int_operator: event.target.value,
                    },
                  });
                }}
              >
                <option value="">Select Operator</option>
                <option value="inferior">&lt;</option>
                <option value="inferior equal">&lt;=</option>
                <option value="superior">&gt;</option>
                <option value="superior equal">&gt;=</option>
                <option value="equal">=</option>
              </Form.Control>
            </Form.Group>
          </>
        );
      case "radio":
        return (
          <Form.Group>
            <Form.Label>Condition Value</Form.Label>
            <Form.Control
              type="text"
              name="conditions"
              value={this.state.newQuestion.conditions}
              onChange={this.handleConditionChange}
            />
          </Form.Group>
        );
      case "checkbox":
        return (
          <Form.Group>
            <Form.Label>Conditions (comma separated values)</Form.Label>
            <Form.Control
              type="text"
              name="conditions"
              value={this.state.newQuestion.conditions}
              onChange={this.handleConditionChange}
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  render() {
    const { type } = this.state.newQuestion;
    const showOptions = ["radio", "checkbox", "dropdown"].includes(type);
    return (
      <div>
        <Row>
          <Col>
            <h5>Questions</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Type</th>
                  <th>Options</th>
                  <th>Conditions</th>
                  <th>Mandatory</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.questions.map((question, index) => (
                  <tr key={index}>
                    <td>{question.question}</td>
                    <td>{question.type}</td>
                    <td>{question.options.join(", ")}</td>
                    <td>
                      {question.conditions?.join(", ")}{" "}
                      {question.condition_int_operator}{" "}
                      {question.condition_int > 0 ? question.condition_int : ""}{" "}
                    </td>
                    <td>{question.mandatory ? "Yes" : "No"}</td>
                    <td>
                      <Button
                        variant="secondary m-1"
                        onClick={() => this.moveQuestion(question.id, "up")}
                      >
                        ↑
                      </Button>
                      <Button
                        variant="secondary m-1"
                        onClick={() => this.moveQuestion(question.id, "down")}
                      >
                        ↓
                      </Button>
                      <Button
                        variant="danger m-1"
                        onClick={() => this.deleteQuestion(question.id)}
                      >
                        <TrashFill></TrashFill>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Create new question</h5>
            <Form>
              <Form.Group>
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  name="question"
                  value={this.state.newQuestion.question}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={this.state.newQuestion.type}
                  onChange={this.handleInputChange}
                >
                  <option value="">Select type</option>
                  <option value="text input">Text Input</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="number input">Number Input</option>
                </Form.Control>
              </Form.Group>
              {showOptions && (
                <Form.Group>
                  <Form.Label>Options (comma separated)</Form.Label>
                  <Form.Control
                    type="text"
                    name="options"
                    onChange={this.handleOptionsChange}
                  />
                </Form.Group>
              )}
              {this.renderConditionInput()}

              <Form.Group>
                <Form.Label>Mandatory</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Is this question mandatory?"
                  name="mandatory"
                  checked={this.state.newQuestion.mandatory}
                  onChange={(event) => {
                    this.setState({
                      newQuestion: {
                        ...this.state.newQuestion,
                        mandatory: event.target.checked,
                      },
                    });
                  }}
                />
              </Form.Group>
              <Button
                className="mt-2"
                variant="primary"
                onClick={this.createQuestion}
              >
                Create Question
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Questions;
