import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { withRouter } from '../withRouter';
import { withTheme } from '../withTheme';

class PresenterFooter extends Component {
  render() {
    return (
      <Navbar style={{ backgroundColor: this.props.backgroundColor }}>
        <Container className='justify-content-between'>
          <Navbar.Brand className='fw-bold' style={{ color: 'var(--light)' }}>
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
              alt=''
              src={this.props.img}
              height='30'
              className='d-inline-block align-top'
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(withTheme(PresenterFooter));
