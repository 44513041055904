import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { withRouter } from '../withRouter';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

class AdminNavBar extends Component {
  render() {
    return (
      <Navbar style={{ backgroundColor: 'black' }}>
        <Container style={{ backgroundColor: 'black' }}>
          <Navbar.Brand href='#home'>
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
              alt=''
              src={require('../assets/logo_mec.png')}
              height='30'
              className='d-inline-block align-top'
            />
          </Navbar.Brand>
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link as={Link} to='/admin' className='text-light'>
                Projects
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(AdminNavBar);
