import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

class BarGraph extends Component {
  adjustRounding(percentageVotes) {
    const rounded = percentageVotes.map((vote) => Math.round(vote));
    let sum = rounded.reduce((a, b) => a + b, 0);
    let error = 100 - sum;

    while (error !== 0) {
      for (let i = 0; i < rounded.length && error !== 0; i++) {
        if (error > 0 && rounded[i] < 100) {
          rounded[i]++;
          error--;
        } else if (error < 0 && rounded[i] > 0) {
          rounded[i]--;
          error++;
        }
      }
    }

    return rounded;
  }

  render() {
    const rawPercentageVotes = Object.values(this.props.percentageVotes);
    const percentageVotes = this.adjustRounding(rawPercentageVotes);

    const labels = Object.keys(this.props.percentageVotes);
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Percentage Votes",
          data: percentageVotes,
          backgroundColor: [
            "#FF0000",
            "#FF0000",
            "#FF0000",

            "#4372C4",
            "#4372C4",
            "#4372C4",
            "#00B050",
            "#00B050",
            "#00B050",
          ],
          borderColor: [
            "#FF0000",
            "#FF0000",
            "#FF0000",

            "#4372C4",
            "#4372C4",
            "#4372C4",
            "#00B050",
            "#00B050",
            "#00B050",
          ],
          // Add the rest of your styling here...
        },
      ],
    };

    const options = {
      aspectRatio: 1.3, // default value. Change it to the

      layout: {
        padding: {
          top: 25, // Adjust this value as needed to add padding at the top
        },
      },

      plugins: {
        legend: {
          display: false, // This will hide the legend
        },
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => (value === 0 ? "" : `${Math.round(value)}%`),
          color: "black",
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          beginAtZero: true,
          grid: {
            display: false, // Hide y-axis grid lines
          },
        },
      },
    };

    return (
      <Bar
        style={{ height: "100%", width: "100%", maxHeight: "50vw" }}
        data={data}
        options={options}
      />
    );
  }
}

export default BarGraph;
