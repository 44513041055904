import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';

class LoadingButton extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  async handleClick() {
    this.setState({ loading: true });
    try {
      await this.props.onClick();
    } catch (error) {
      // Handle or log error if needed
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { onClick, children, ...otherProps } = this.props;

    return (
      <Button
        onClick={() => this.handleClick()}
        {...otherProps}
        disabled={loading || otherProps.disabled}
      >
        {loading ? <Spinner animation='border' size='sm' /> : children}
      </Button>
    );
  }
}

export default LoadingButton;
