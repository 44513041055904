import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { withRouter } from "../withRouter";
import { withTheme } from "../withTheme";

class VoterNavBar extends Component {
  render() {
    return (
      <Navbar
        style={{ backgroundColor: this.props.backgroundColor, height: 80 }}
      >
        <Container className="justify-content-between">
          <Navbar.Brand className="fw-bold" style={{ color: "var(--light)" }}>
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = "none";
              }}
              alt=""
              style={{
                objectFit: "contain",
                maxWidth: "100%",
              }}
              src={this.props.img}
              height="70"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          {this.props.text && (
            <Navbar.Text
              className="mx-auto fw-bold fs-4"
              style={{ color: "var(--light)" }}
            >
              {this.props.text}
            </Navbar.Text>
          )}
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(withTheme(VoterNavBar));
