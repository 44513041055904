import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { withRouter } from "../withRouter";
import {
  authVoter,
  getEventByUrl,
  getEventColors,
  getVoterByLink,
} from "../api";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import VoterNavBar from "./VoterNavBar.js";
import { withTheme } from "../withTheme";
import bandesPhoviaH from "../assets/bandes_phovia_h.svg";
import { BASE_URL } from "../config";
import LoadingButton from "./LoadingButton";

class Voter extends Component {
  state = {
    username: "",
    event: null,
    showAlert: "",
    loading: true,
    showRegister: true,
  };
  vh = window.innerHeight * 0.01;

  async componentDidMount() {
    this.setVHValue();
    window.addEventListener("resize", this.setVHValue);

    // Replace this URL with the correct endpoint URL
    this.setState({ loading: true });
    try {
      const event = await getEventByUrl(this.props.params.unique_str);
      const colors = await getEventColors(event.id);

      this.setState({ event, loading: false, colors });
    } catch (error) {
      let error_msg = "";
      if (error.response && error.response.data)
        error_msg = error.response.data.detail;
      else error_msg = error.message;
      this.setState({
        error: error_msg,
        showAlert: "danger",
        loading: false,
      });
    }
  }
  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener("resize", this.setVHValue);
  }
  setVHValue = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  handleChange = (event) => {
    this.setState({
      username: event.target.value,
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    try {
      if (this.state.event.with_presenter) {
        await authVoter(
          this.state.event.id,
          this.state.username,
          this.props.params.unique_user_link
        );
        this.setState({ loading: false });
        this.props.navigate(
          "/events/" + this.props.params.unique_str + "/voter/session"
        );
      } else {
        if (this.state.event.form_placement === "end") {
          await authVoter(
            this.state.event.id,
            this.state.username,
            this.props.params.unique_user_link
          );
          this.props.navigate(
            "/events/" + this.props.params.unique_str + "/voter/session"
          );
        } else {
          const answersOk = await getVoterByLink(
            this.state.event.id,
            this.props.params.unique_user_link
          );
          if (answersOk.questions_mandatory) {
            await authVoter(
              this.state.event.id,
              this.state.username,
              this.props.params.unique_user_link
            );
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter/session"
            );
          } else {
            this.props.navigate(
              "/events/" + this.props.params.unique_str + "/voter/register",
              {
                state: {
                  event_id: this.state.event.id,
                  langage: this.state.event.langage,
                  form_placement: this.state.event.form_placement,
                  position: "start",
                  unique_link: this.props.params.unique_user_link,
                  event_refused_message: this.state.event.event_refused_message,
                  with_presenter: this.state.event.with_presenter,
                  unique_str: this.props.params.unique_str,
                },
              }
            );
          }
        }
      }
    } catch (error) {
      this.setState({
        error: error.response.data.detail,
        showAlert: "danger",
        loading: false,
      });
    }
  };

  render() {
    const { event, colors, showAlert, error, loading, username } = this.state;
    const lang = event?.langage;
    const translations = {
      welcome:
        lang === "fr"
          ? "Bienvenue sur la plateforme de vote Delphi."
          : "Welcome to DELPHI voting.",
      register:
        lang === "fr"
          ? "Si vous ne vous êtes pas encore connecté(e) à la plateforme, vous pouvez vous inscrire :"
          : "If you haven't registered to the event yet:",
      enterUsername: this.state.showRegister
        ? lang === "fr"
          ? "Si vous vous êtes déjà inscrit(e), vous pouvez reprendre votre session de vote Delphi en rentrant le pseudo choisi lors de votre inscription :"
          : "else, please enter your username to resume your session:"
        : lang === "fr"
        ? "Entrez votre pseudo pour reprendre votre session de vote Delphi:"
        : "Enter your username to resume your voting session:",
      usernamePlaceholder: lang === "fr" ? "Nom d'utilisateur" : "Username",
      enter:
        lang === "fr"
          ? event?.with_presenter
            ? "Rejoindre"
            : "Démarrer"
          : event?.with_presenter
          ? "Enter"
          : "Start",
      registerButton: lang === "fr" ? "M'inscrire" : "Register",
      error: lang === "fr" ? "Erreur" : "Error",
      success: lang === "fr" ? "Succès" : "Success",
      close: lang === "fr" ? "Fermer" : "Close",
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "54px",
          minHeight: "calc(var(--vh, 1vh) * 100)", // Updated minHeight
          backgroundColor: colors?.background_color,
          position: "relative",
          overflowX: "hidden",
        }}
      >
        {event && (
          <img
            src={
              event
                ? BASE_URL + "/events/" + event.id + "/data/voter_footer"
                : null
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = "none";
            }}
            alt=""
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              height: "54px",
              objectFit: "fill",
            }}
          />
        )}

        <VoterNavBar
          backgroundColor={colors?.header_background_color}
          img={event ? BASE_URL + "/events/" + event.id + "/data/logo" : null}
        ></VoterNavBar>
        <Container
          style={{ maxWidth: "800px" }}
          fluid
          className="px-3 pt-3 flex-grow-1 d-flex flex-column"
        >
          <Alert show={showAlert !== ""} variant={showAlert}>
            <Alert.Heading>
              {showAlert === "danger"
                ? translations.error
                : translations.success}
            </Alert.Heading>
            <p>{error}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => this.setState({ showAlert: "" })}
                variant={"outline-" + showAlert}
              >
                {translations.close}
              </Button>
            </div>
          </Alert>
          {!loading && event ? (
            <>
              <div
                className="pb-3"
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="mb-5" style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <h2 style={{ marginLeft: 0, textAlign: "center" }}>
                      {event?.event_title}
                    </h2>
                    {event?.event_subtitle && (
                      <h3 style={{ marginLeft: 0, textAlign: "center" }}>
                        {event?.event_subtitle}
                      </h3>
                    )}
                  </div>
                  <div style={{ display: "flex", flex: 1 }}>
                    <div
                      style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{
                        __html: event?.event_description,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 3,
                    flexDirection: "column",
                  }}
                >
                  {this.state.event.with_presenter && (
                    <div
                      className="mb-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <span className="mb-2 fw-bold">
                        {translations.register}
                      </span>
                      <div style={{ width: "100%" }}>
                        <Button
                          style={{ width: "100%" }}
                          variant="dark"
                          size="lg"
                          onClick={() =>
                            this.props.navigate(
                              `/events/${this.props.params.unique_str}/voter/register`,
                              {
                                state: {
                                  event_id: this.state.event.id,
                                  langage: this.state.event.langage,
                                  form_placement:
                                    this.state.event.form_placement,
                                  position: "start",
                                  unique_link:
                                    this.props.params.unique_user_link,
                                  event_refused_message:
                                    this.state.event.event_refused_message,
                                  with_presenter:
                                    this.state.event.with_presenter,
                                  unique_str: this.props.params.unique_str,
                                },
                              }
                            )
                          }
                        >
                          {translations.registerButton}
                        </Button>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form>
                      {this.state.event.with_presenter && (
                        <>
                          <Form.Label>{translations.enterUsername}</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="username"
                            placeholder={translations.usernamePlaceholder}
                            value={username}
                            onChange={this.handleChange}
                          />
                        </>
                      )}

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingButton
                          onClick={this.handleSubmit.bind(this)}
                          variant="dark"
                          className="mb-3 mt-2"
                          size="lg"
                        >
                          {translations.enter}
                        </LoadingButton>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spinner
              animation="grow"
              variant="primary"
              style={{ alignSelf: "center" }}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(withTheme(Voter));
