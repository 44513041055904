import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { withRouter } from "../withRouter";
import {
  authPresenter,
  getEventByUrl,
  getEventColors,
  isPresenterAuth,
  launchEvent,
} from "../api";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { withTheme } from "../withTheme";
import PresenterNavBar from "./PresenterNavBar";
import PresenterFooter from "./PresenterFooter";
import bandesPhovia from "../assets/bandes_phovia.svg";
import { BASE_URL } from "../config";
import LoadingButton from "./LoadingButton";

class Presenter extends Component {
  state = {
    event: null,
    showLogin: false,
    showModal: false,
    password: "",
    showAlertPassword: "",
    showAlert: "",
  };
  getDomainName() {
    const domainName = window.location.hostname;
    const protocol = window.location.protocol;
    const port = window.location.port;
    const url = protocol + "//" + domainName + (port ? ":" + port : "");
    return url;
  }

  async componentDidMount() {
    // Replace this URL with the correct endpoint URL

    try {
      const event = await getEventByUrl(this.props.params.unique_str);
      const colors = await getEventColors(event.id);
      const url = this.getDomainName();
      this.setState({
        event,
        colors,
        voter_url: event.voter_url.replace("https://nomdedomaine.com", url),
      });
      try {
        await isPresenterAuth(event.id);
      } catch (e) {
        console.log(e);
        if (e.response) {
          if (
            e.response.status === 401 ||
            e.response.status === 403 ||
            e.response.status === 402 ||
            e.response.status === 400
          ) {
            // Redirect to another page if the error is 400
            this.setState({ showLogin: true });
            return;
          }
        } else if (e.message && e.message === "No token found") {
          this.setState({ showLogin: true });
          return;
        }
        throw e;
      }
    } catch (error) {
      let error_msg = "";
      if (error.response && error.response.data) {
        error_msg = error.response.data.detail;
      } else {
        error_msg = error.message;
      }
      this.setState({
        showAlert: "danger",
        error: error_msg,
      });
    }
  }

  async launchSession() {
    try {
      await launchEvent(this.state.event.id);
      this.props.navigate(
        "/events/" + this.props.params.unique_str + "/presenter/session"
      );
    } catch (error) {
      this.setState({ showAlert: "danger", error: error.response.data.detail });
    }
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handlePasswordSubmit = async () => {
    try {
      await authPresenter(this.state.event.id, this.state.password);
    } catch (error) {
      this.setState({
        showAlertPassword: "danger",
        error: error.response.data.detail,
      });
      return;
    }

    this.setState({ showLogin: false });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: this.state.colors?.background_color,
        }}
      >
        <img
          src={
            this.state.event
              ? BASE_URL +
                "/events/" +
                this.state.event.id +
                "/data/presenter_background"
              : null
          }
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = "none";
          }}
          style={{
            position: "absolute", // Absolute positioning
            top: 0, // Align to the top
            left: "5%", // Align to the left
            // Full width
            height: "100%", // Full height
            objectFit: "fill", // Forces the content to fill the container, ignoring aspect ratio
          }}
        />
        <PresenterNavBar
          backgroundColor={this.state.colors?.header_background_color}
          text="DELPHI VOTING - GIVE ME 5"
        ></PresenterNavBar>

        <Container
          fluid
          className="p-3 px-5"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden", // Prevent scrolling within the main content
            position: "relative",
          }}
        >
          <Alert show={this.state.showAlert === "danger"} variant={"danger"}>
            <Alert.Heading>Event not found</Alert.Heading>
            <p>{this.state.error}</p>
          </Alert>
          {this.state.event ? (
            <>
              <div>
                <Row>
                  <Card
                    style={{
                      marginBottom: "15px",
                      backgroundColor:
                        this.state.colors?.statement_background_color,
                      borderWidth: "0px",
                      boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
                    }}
                  >
                    <Card.Body>
                      <Row md={2} xs={1}>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Card.Title className="text-light fs-3">
                            {this.state.event.name}
                          </Card.Title>
                          <Card.Subtitle className="text-light">
                            {new Date(
                              this.state.event.start_date
                            ).toLocaleDateString()}{" "}
                            -{" "}
                            {new Date(
                              this.state.event.end_date
                            ).toLocaleDateString()}
                          </Card.Subtitle>
                          <Badge
                            className="mt-3"
                            bg={
                              this.state.status === "finished"
                                ? "success"
                                : "warning"
                            }
                          >
                            {this.state.status}
                          </Badge>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Row
                  xs={1}
                  md={2}
                  className="pb-3 justify-content-center"
                  style={{ width: "100%" }}
                >
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor:
                          this.state.colors?.header_background_color,
                      }}
                      className="p-4"
                    >
                      <h3 className="text-light text-center fs-3 mb-3">
                        Scan this QR Code to access the event:
                      </h3>
                      <div
                        style={{
                          paddingTop: "30%", // Setting the padding-top to the same value as the image height
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={
                            BASE_URL +
                            "/events/" +
                            this.state.event.id +
                            "/voter_qrcode"
                          }
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4 className="mt-3 text-light fs-4 text-center">
                          or go to this link:
                        </h4>
                        <span
                          className="p-1 px-2 fs-7 fw-bold text-center"
                          style={{
                            backgroundColor:
                              this.state.colors?.background_color,
                            color: this.state.colors?.header_background_color,
                            borderRadius: "10px",
                          }}
                        >
                          {this.state.voter_url}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <LoadingButton
                  variant="outline-dark"
                  onClick={this.launchSession.bind(this)}
                >
                  {this.state.event?.status === "to come"
                    ? "Launch session"
                    : "Resume session"}
                </LoadingButton>
              </div>
            </>
          ) : null}
          {this.state.showLogin ? (
            <Modal show={this.state.showLogin}>
              <Modal.Header>
                <Modal.Title>Enter event password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Alert
                  show={this.state.showAlertPassword === "danger"}
                  variant={"danger"}
                >
                  <Alert.Heading>Incorrect password</Alert.Heading>
                  <p>Please retry.</p>
                </Alert>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <LoadingButton
                  variant="primary"
                  onClick={this.handlePasswordSubmit}
                >
                  Submit
                </LoadingButton>
              </Modal.Footer>
            </Modal>
          ) : null}
        </Container>
        <PresenterFooter
          backgroundColor={this.state.colors?.header_background_color}
          img={
            this.state.event
              ? BASE_URL + "/events/" + this.state.event.id + "/data/logo"
              : null
          }
        ></PresenterFooter>
      </div>
    );
  }
}

export default withRouter(withTheme(Presenter));
