import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import AdminNavBar from './AdminNavBar';

class Fallback extends Component {
  state = {
    projects: [],
  };

  render() {
    return (
      <div>
        <AdminNavBar />
        <div className='p-3'>
          <h1 className='text-center'>Oooops, this page does not exist.</h1>
        </div>
      </div>
    );
  }
}

export default withRouter(Fallback);
