import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { withRouter } from "../withRouter";
import {
  createEvent,
  deleteEvent,
  exportVoters,
  exportVotes,
  generateVoters,
  getEvent,
  getEventVoters,
  getProject,
  updateEvent,
  uploadEventQrCode,
} from "../api";
import AdminNavBar from "./AdminNavBar";
import LoadingButton from "./LoadingButton";
import {
  CalendarEvent,
  InfoCircle,
  ClipboardFill,
  BarChart,
  Download,
  PersonFill,
} from "react-bootstrap-icons";
import { Spinner } from "react-bootstrap";
import { BASE_URL } from "../config";

class ModifyEvent extends Component {
  state = {
    name: "",
    start_date: "",
    end_date: "",
    with_presenter: "yes",
    with_comments: "yes",
    with_no_opinion: "yes",
    status: "to come",
    langage: "fr",
    form_placement: "start",
    presenter_url: "example.com/presenter", // Update with actual URL
    presenter_password: "123456", // Update with actual password
    voter_url: "example.com/voter", // Update with actual URL
    voter_qrcode_url: "",
    event_subtitle: "",
    voter_qrcode_url_upload: "",
    isUploadingStatements: false,
    isUploadingLogo: false,
    showInformationAlert: "",
    showLogoUploadAlert: "",
    event_end_contact: "",
    showStatementUploadAlert: "",
    len_voters: 0,
    event_title: "",
    event_description: "",
    statement_version: 1,
    voters: [],
  };

  handleDelete = async () => {
    try {
      await deleteEvent(this.props.params.event_id);
      this.props.navigate(`/admin`);
    } catch (err) {
      this.handleError(err);
    }
  };

  handleCopyClick(valueFromState) {
    navigator.clipboard.writeText(this.state[valueFromState]);
  }

  formatDate = (date) => {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  getDomainName() {
    const domainName = window.location.hostname;
    const protocol = window.location.protocol;
    const port = window.location.port;
    const url = protocol + "//" + domainName + (port ? ":" + port : "");
    return url;
  }

  async componentDidMount() {
    if (this.props.params.event_id !== "new") {
      this.setState({ loading: true });
      try {
        const url = this.getDomainName();
        const event = await getEvent(this.props.params.event_id);
        this.setState({
          name: event.name,
          start_date: this.formatDate(event.start_date),
          end_date: this.formatDate(event.end_date),
          presenter_url: event.presenter_url.replace(
            "https://nomdedomaine.com",
            url
          ),
          event_refused_message: event.event_refused_message,
          event_end_message: event.event_end_message,
          event_end_contact: event.event_end_contact,
          event_title: event.event_title,
          event_subtitle: event.event_subtitle,
          event_description: event.event_description,
          statement_version: event.statement_version,
          langage: event.langage,
          len_voters:
            event.voters?.filter((voter) => voter.event_status === "finished")
              .length || 0,
          presenter_password: event.presenter_password,
          voter_url: event.voter_url.replace("https://nomdedomaine.com", url),
          voter_qrcode_url: event.voter_qrcode_url,
          status: event.status,
          with_presenter: event.with_presenter ? "yes" : "no",
          with_comments: event.with_comments ? "yes" : "no",
          form_placement: event.form_placement,
        });

        if (!event.with_presenter) {
          const voters = await getEventVoters(this.props.params.event_id);
          this.setState({ voters: voters });
        }
      } catch (err) {
        this.handleError(err);
      }
      this.setState({ loading: false });
    } else {
      const project = await getProject(this.props.params.project_id);
      this.setState({ number_of_versions: project.number_of_versions });
    }
  }

  generateVoters = async (event_id, number_of_voters) => {
    try {
      const voters = await generateVoters(event_id, number_of_voters);
      this.setState({ voters: voters });
    } catch (error) {
      this.handleError(error);
    }
  };

  handleError(e) {
    let error_message = "";
    console.log("error", e);

    if (e.response) {
      switch (e.response.status) {
        case 400:
          // Redirect to another page if the error is 400
          this.props.navigate("/admin");

          return; // exit from the function
        case 401:
          this.props.navigate("/admin");

          return; // or other specific error codes
        case 403:
          this.props.navigate("/admin");

          return; // or other specific error codes
        case 404:
          // Display an alert if error is 401 or 404
          error_message = e.response.data.detail || "An error occurred.";
          break;
        default:
          // Display the error detail from the server
          error_message =
            e.response.data.detail || "An unexpected error occurred.";
          break;
      }
    } else if (e.message && e.message === "No token found") {
      this.props.navigate("/admin");
      return;
    } else {
      // Handle the scenario where there's no response (likely a network/connection error)
      error_message = "A connection error occurred. Please try again later.";
    }

    // Set the state to reflect the error
    this.setState({ showInformationAlert: "danger", error: error_message });
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;

    if (target.type === "file") {
      // Handle file input
      const file = target.files[0]; // Get the selected file
      this.setState({ [name]: file }); // You can set the file object to your state
    } else {
      // Handle other input types
      const value = target.value;
      this.setState({ [name]: value });
    }
  };

  handleSubmit = async () => {
    let event;
    if (this.props.params.event_id === "new") {
      event = await createEvent({
        name: this.state.name,
        langage: this.state.langage,
        project_id: this.props.params.project_id,
        start_date: new Date(this.state.start_date).toISOString(),
        end_date: new Date(this.state.end_date).toISOString(),
        event_subtitle: this.state.event_subtitle,
        with_presenter: this.state.with_presenter === "yes",
        with_comments: this.state.with_comments === "yes",
        with_no_opinion: this.state.with_no_opinion === "yes",
        statement_version: this.state.statement_version,
        event_title: this.state.event_title,
        event_description: this.state.event_description,
        len_voters: 0,
        form_placement: this.state.form_placement,
        event_refused_message: this.state.event_refused_message,
        event_end_message: this.state.event_end_message,
        event_end_contact: this.state.event_end_contact,
      });

      this.props.navigate(
        `/admin/project/${this.props.params.project_id}/event/${event.id}`
      );
    } else {
      try {
        if (
          this.state.voter_qrcode_url_upload &&
          this.state.voter_qrcode_url_upload !== ""
        ) {
          await uploadEventQrCode(
            this.props.params.event_id,
            this.state.voter_qrcode_url_upload
          );
        }
        event = await updateEvent(this.props.params.event_id, {
          name: this.state.name,
          project_id: this.props.params.project_id,
          langage: this.state.langage,
          event_title: this.state.event_title,
          event_subtitle: this.state.event_subtitle,
          event_description: this.state.event_description,
          event_refused_message: this.state.event_refused_message,
          event_end_message: this.state.event_end_message,
          event_end_contact: this.state.event_end_contact,
          start_date: new Date(this.state.start_date).toISOString(),
          end_date: new Date(this.state.end_date).toISOString(),
        });
      } catch (err) {
        this.handleError(err);
      }
    }
    const url = this.getDomainName();
    this.setState({
      ...event,
      presenter_url: event.presenter_url.replace(
        "https://nomdedomaine.com",
        url
      ),
      langage: event.langage,
      voter_url: event.voter_url.replace("https://nomdedomaine.com", url),
      statement_version: event.statement_version,
      start_date: this.formatDate(event.start_date),
      end_date: this.formatDate(event.end_date),
      event_title: event.event_title,
      event_description: event.event_description,
      with_presenter: event.with_presenter ? "yes" : "no",
      with_comments: event.with_comments ? "yes" : "no",
      with_no_opinion: event.with_no_opinion ? "yes" : "no",
      showInformationAlert: "success",
      event_subtitle: event.event_subtitle,
      form_placement: event.form_placement,
      event_refused_message: event.event_refused_message,
      event_end_message: event.event_end_message,
      event_end_contact: event.event_end_contact,
    });
  };

  async exportLinks() {
    try {
      await exportVoters(this.props.params.event_id);
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportVotes() {
    try {
      await exportVotes(this.props.params.event_id);
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "#F6F7F9", minHeight: "100vh" }}>
        <AdminNavBar />
        <Container fluid className="px-5 pt-3 pb-5">
          <div
            className="border-bottom mb-3"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CalendarEvent size={26}></CalendarEvent>
              <h2 className="ms-3">Event</h2>
            </div>
          </div>
          {this.state.loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner></Spinner>
            </div>
          ) : (
            <Card
              className="mb-3"
              style={{
                borderWidth: 0,
                boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
              }}
            >
              <Card.Body>
                <Card.Title>{this.state.name}</Card.Title>
                <Card.Subtitle>
                  {this.state.start_date !== ""
                    ? new Date(this.state.start_date).toLocaleDateString() +
                      " - "
                    : ""}
                  {this.state.end_date !== ""
                    ? new Date(this.state.end_date).toLocaleDateString()
                    : ""}
                </Card.Subtitle>

                <Badge
                  className="mt-3"
                  bg={this.state.status === "finished" ? "success" : "warning"}
                >
                  {this.state.with_presenter == "yes"
                    ? this.state.status
                    : this.state.len_voters + " voter(s)"}
                </Badge>
              </Card.Body>
            </Card>
          )}

          <div
            className="border-bottom mb-3"
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoCircle size={26}></InfoCircle>
              <h3 className="ms-3">Information</h3>
            </div>
          </div>
          <Alert
            show={this.state.showInformationAlert !== ""}
            variant={this.state.showInformationAlert}
          >
            <Alert.Heading>
              {this.state.showInformationAlert === "success"}
            </Alert.Heading>
            <p>
              {this.state.showInformationAlert === "success"
                ? "Information successfully updated."
                : "Error when updating information : " + this.state.error}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => this.setState({ showInformationAlert: "" })}
                variant={"outline-" + this.state.showInformationAlert}
              >
                Close
              </Button>
            </div>
          </Alert>
          {this.state.loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner></Spinner>
            </div>
          ) : (
            <Form>
              <Row xs={1} md={4} className="mb-3">
                <Col>
                  <Form.Label>Event Name</Form.Label>
                  <Form.Control
                    disabled={this.state.status !== "to come"}
                    required
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </Col>

                <Col>
                  <Form.Label>Event Language</Form.Label>
                  <Form.Check
                    type="radio"
                    name="langage"
                    value="fr"
                    checked={this.state.langage === "fr"}
                    onChange={this.handleChange}
                    label="FR"
                  />
                  <Form.Check
                    type="radio"
                    name="langage"
                    value="en"
                    checked={this.state.langage === "en"}
                    onChange={this.handleChange}
                    label="EN"
                  />
                </Col>
                <Col>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    disabled={this.state.status !== "to come"}
                    required
                    type="date"
                    name="start_date"
                    value={this.state.start_date}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    disabled={this.state.status !== "to come"}
                    required
                    type="date"
                    name="end_date"
                    value={this.state.end_date}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col>
                  <Form.Label>With presenter ?</Form.Label>
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="with_presenter"
                    value="yes"
                    checked={this.state.with_presenter === "yes"}
                    onChange={this.handleChange}
                    label="Yes"
                  />
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="with_presenter"
                    value="no"
                    checked={this.state.with_presenter === "no"}
                    onChange={this.handleChange}
                    label="No"
                  />
                </Col>
                <Col>
                  <Form.Label>With comments ?</Form.Label>
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="with_comments"
                    value="yes"
                    checked={this.state.with_comments === "yes"}
                    onChange={this.handleChange}
                    label="Yes"
                  />
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="with_comments"
                    value="no"
                    checked={this.state.with_comments === "no"}
                    onChange={this.handleChange}
                    label="No"
                  />
                </Col>
                <Col>
                  <Form.Label>With "no opinion" ?</Form.Label>
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="with_no_opinion"
                    value="yes"
                    checked={this.state.with_no_opinion === "yes"}
                    onChange={this.handleChange}
                    label="Yes"
                  />
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="with_no_opinion"
                    value="no"
                    checked={this.state.with_no_opinion === "no"}
                    onChange={this.handleChange}
                    label="No"
                  />
                </Col>
                <Col>
                  <Form.Label>Statement Version</Form.Label>
                  <Form.Control
                    as="select"
                    name="statement_version"
                    value={this.state.statement_version}
                    onChange={this.handleChange}
                    disabled={this.props.params.event_id !== "new"}
                  >
                    {this.props.params.event_id === "new" ? (
                      [...Array(this.state.number_of_versions).keys()].map(
                        (version) => (
                          <option key={version + 1} value={version + 1}>
                            {version + 1}
                          </option>
                        )
                      )
                    ) : (
                      <option> {this.state.statement_version} </option>
                    )}
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Label>Event Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="event_title"
                    value={this.state.event_title}
                    onChange={this.handleChange}
                    placeholder="Enter event title"
                  />
                </Col>
                <Col>
                  <Form.Label>Event Subtitle</Form.Label>
                  <Form.Control
                    type="text"
                    name="event_subtitle"
                    value={this.state.event_subtitle}
                    onChange={this.handleChange}
                    placeholder="Enter event subtitle"
                  />
                </Col>
                <Col>
                  <Form.Label>Event Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="event_description"
                    value={this.state.event_description}
                    onChange={this.handleChange}
                    placeholder="Enter event description"
                  />
                </Col>
                <Col>
                  <Form.Label>Conditions Not Met Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="event_refused_message"
                    value={this.state.event_refused_message}
                    onChange={this.handleChange}
                    placeholder="Enter message"
                  />
                </Col>
                <Col>
                  <Form.Label>End Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="event_end_message"
                    value={this.state.event_end_message}
                    onChange={this.handleChange}
                    placeholder="Enter message"
                  />
                </Col>
                <Col>
                  <Form.Label>End Contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="event_end_contact"
                    value={this.state.event_end_contact}
                    onChange={this.handleChange}
                    placeholder="example@email.com"
                  />
                </Col>
                <Col>
                  <Form.Label>Questions Form Position</Form.Label>
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="form_placement"
                    value="start"
                    checked={this.state.form_placement === "start"}
                    onChange={this.handleChange}
                    label="Start"
                  />
                  <Form.Check
                    disabled={this.props.params.event_id !== "new"}
                    type="radio"
                    name="form_placement"
                    value="end"
                    checked={this.state.form_placement === "end"}
                    onChange={this.handleChange}
                    label="End"
                  />
                </Col>
              </Row>
              {this.props.params.event_id !== "new" && (
                <>
                  {this.state.with_presenter === "yes" && (
                    <Row xs={1} md={3} className="mb-3">
                      <Col style={{}}>
                        <div
                          className="mb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Form.Label style={{ margin: 0 }}>
                              Presenter Access URL
                            </Form.Label>
                          </div>
                          <ClipboardFill
                            style={{ cursor: "pointer" }}
                            onClick={this.handleCopyClick.bind(
                              this,
                              "presenter_url"
                            )}
                          ></ClipboardFill>
                        </div>
                        <div>
                          <Badge>{this.state.presenter_url}</Badge>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="mb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Form.Label style={{ margin: 0 }}>
                              Presenter Password
                            </Form.Label>
                          </div>
                          <ClipboardFill
                            style={{ cursor: "pointer" }}
                            onClick={this.handleCopyClick.bind(
                              this,
                              "presenter_password"
                            )}
                          ></ClipboardFill>
                        </div>
                        <div>
                          <Badge>{this.state.presenter_password}</Badge>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {this.state.with_presenter === "yes" && (
                    <Row xs={1} md={3} className="mb-3">
                      <Col>
                        <div
                          className="mb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Form.Label style={{ margin: 0 }}>
                              Voter Access URL
                            </Form.Label>
                          </div>
                          <ClipboardFill
                            style={{ cursor: "pointer" }}
                            onClick={this.handleCopyClick.bind(
                              this,
                              "voter_url"
                            )}
                          ></ClipboardFill>
                        </div>

                        <div>
                          <Badge>{this.state.voter_url}</Badge>
                        </div>
                      </Col>

                      <Col>
                        <Form.Label>Voter QRCode URL</Form.Label>
                        <Form.Control
                          required
                          onChange={this.handleChange}
                          name="voter_qrcode_url_upload"
                          type="file"
                          disabled={this.state.status !== "to come"}
                        />
                      </Col>
                      {this.state.voter_qrcode_url && (
                        <Col>
                          <img
                            key={this.state.voter_qrcode_url}
                            style={{
                              height: "100px",
                              aspectRatio: 1,
                              resizeMode: "contain",
                            }}
                            src={
                              BASE_URL +
                              "/events/" +
                              this.props.params.event_id +
                              "/voter_qrcode"
                            }
                          ></img>
                        </Col>
                      )}
                    </Row>
                  )}
                </>
              )}

              <LoadingButton
                className="me-3"
                variant="primary"
                onClick={this.handleSubmit.bind(this)}
                disabled={this.state.status !== "to come"}
              >
                {this.props.params.event_id === "new"
                  ? "Create event"
                  : "Save information"}
              </LoadingButton>
              {this.props.params.event_id !== "new" && (
                <LoadingButton
                  variant="danger"
                  onClick={this.handleDelete.bind(this)}
                >
                  Delete event
                </LoadingButton>
              )}
            </Form>
          )}
          {this.state.with_presenter === "no" &&
          this.props.params.event_id !== "new" ? (
            <>
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonFill size={26}></PersonFill>
                  <h3 className="ms-3">Voters</h3>
                </div>
              </div>
              <Form>
                <Form.Group controlId="formGenerateVoters" className="mb-3">
                  <Form.Label>Number of Voters to Generate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number"
                    onChange={(e) =>
                      this.setState({ generateVoterNumber: e.target.value })
                    }
                  />
                </Form.Group>
                <LoadingButton
                  variant="primary"
                  onClick={() =>
                    this.generateVoters(
                      this.props.params.event_id,
                      this.state.generateVoterNumber
                    )
                  }
                >
                  Generate Voters
                </LoadingButton>
              </Form>

              {this.state.voters.length > 0 && (
                <>
                  <LoadingButton
                    className="mt-3 mb-3"
                    onClick={() => this.exportLinks()}
                  >
                    <Download className="me-1"></Download>
                    Export links
                  </LoadingButton>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Link</th>
                        <th>Current Statement Number</th>
                        <th>Event Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.voters
                        .sort((a, b) => a.id - b.id)
                        .map((voter, index) => (
                          <tr key={index}>
                            <td>
                              {this.state.voter_url.replace(
                                "https://nomdedomaine.com",
                                this.getDomainName()
                              ) +
                                "/" +
                                voter.link || "N/A"}
                            </td>
                            <td>{voter.current_statement_number}</td>
                            <td>{voter.event_status}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              )}
            </>
          ) : null}
          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (this.state.status === "finished" &&
              this.state.with_presenter === "yes") ||
            (this.state.with_presenter && this.state.len_voters > 0) ? (
            <>
              <div
                className="border-bottom mb-3 mt-3"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BarChart size={26}></BarChart>
                  <h3 className="ms-3">Results</h3>
                </div>
              </div>

              <LoadingButton onClick={() => this.exportVotes()}>
                <Download className="me-1"></Download>
                Export Results
              </LoadingButton>
              <LoadingButton
                className="ms-3"
                onClick={() =>
                  this.props.navigate(
                    "/admin/project/" +
                      this.props.params.project_id +
                      "/event/" +
                      this.props.params.event_id +
                      "/presenter_view"
                  )
                }
              >
                <BarChart className="me-1"></BarChart>
                See graphs
              </LoadingButton>
            </>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default withRouter(ModifyEvent);
