import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { withRouter } from '../withRouter';
import {
  finishVotingEvent,
  getCurrentStatement,
  getEventByUrl,
  getEventColors,
  getNbVotesStatement,
  getStatementResults,
  isPresenterAuth,
  nextStatementEvent,
} from '../api';
import {
  QuestionCircleFill,
  PhoneFill,
  BarChartFill,
  PersonLinesFill,
  Square,
} from 'react-bootstrap-icons';
import BarGraph from './BarGraph';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import PresenterNavBar from './PresenterNavBar';
import PresenterFooter from './PresenterFooter';
import bandesPhovia from '../assets/bandes_phovia.svg';
import { withTheme } from '../withTheme';
import { BASE_URL } from '../config';
import LoadingButton from './LoadingButton';
import { Spring, animated } from '@react-spring/web';

class PresenterSession extends Component {
  state = {
    event: null,
    currentStatement: null,
    mode: 'vote',
    loading: false,
    error_message: '',
    error: false,
    nbVotes: 0,
  };
  intervalId = null; // This will store the interval ID

  fetchNbVotes = async () => {
    try {
      let response = await getNbVotesStatement(
        this.state.event.id,
        this.state.currentStatement.id
      );

      this.setState({ nbVotes: response.number }); // Assuming the API returns an object with a votes property
    } catch (error) {
      console.error('There was an error fetching the data:', error);
    }
  };

  startRequesting = () => {
    if (this.intervalId === null) {
      this.intervalId = setInterval(this.fetchNbVotes, 1000);
    }
  };

  stopRequesting = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  async displayResults() {
    try {
      this.stopRequesting();
      this.setState({ loading: true, nbVotes: 0 });
      await finishVotingEvent(this.state.event.id);
      const results = await getStatementResults(
        this.state.event.id,
        this.state.currentStatement.id
      );
      this.setState({ results });
    } catch (error) {
      this.handleError(error);
    }
    this.setState({ mode: 'results', loading: false });
  }

  formatStatement(statement) {
    return statement.split('<br/>').map((text, index, array) => (
      <>
        {text}
        {/* Render a <br /> for all but the last segment */}
        {index === array.length - 1 ? null : <br />}
      </>
    ));
  }

  handleError(e) {
    let error_message = '';
    console.log('error', e);

    if (e.response) {
      switch (e.response.status) {
        case 400:
          // Redirect to another page if the error is 400
          this.props.navigate(
            '/events/' + this.props.params.unique_str + '/presenter'
          );
          return; // exit from the function
        case 401:
          this.props.navigate(
            '/events/' + this.props.params.unique_str + '/presenter'
          );
          return; // or other specific error codes
        case 403:
          this.props.navigate(
            '/events/' + this.props.params.unique_str + '/presenter'
          );
          return; // or other specific error codes
        case 404:
          // Display an alert if error is 401 or 404
          error_message = e.response.data.detail || 'An error occurred.';
          break;
        default:
          // Display the error detail from the server
          error_message =
            e.response.data.detail || 'An unexpected error occurred.';
          break;
      }
    } else if (e.message && e.message === 'No token found') {
      this.props.navigate(
        '/events/' + this.props.params.unique_str + '/presenter'
      );
      return;
    } else {
      // Handle the scenario where there's no response (likely a network/connection error)
      error_message = 'A connection error occurred. Please try again later.';
    }

    // Set the state to reflect the error
    this.setState({ error: true, error_message, loading: false });
  }

  renderConsensus() {
    if (
      this.state.results.percentage_votes_gte_7 >= 75 &&
      this.state.results.median >= 8
    )
      return (
        <div
          className='p-3'
          style={{
            backgroundColor: 'rgba(52, 172, 74, 1)',
            borderRadius: '10px',
          }}
        >
          <span
            className='fs-3 fw-bold text-align-center'
            style={{ color: 'var(--light)' }}
          >
            Strong consensus
          </span>
        </div>
      );
    if (
      this.state.results.percentage_votes_gte_7 >= 75 ||
      this.state.results.median >= 8
    )
      return (
        <div
          className='p-3'
          style={{
            backgroundColor: '#D1E7DD',
            borderRadius: '10px',
          }}
        >
          <span
            className='fs-3 fw-bold text-align-center'
            style={{ color: 'var(--dark)' }}
          >
            Good consensus
          </span>
        </div>
      );

    return (
      <div
        className='p-3'
        style={{
          backgroundColor: '#E2E2E2',
          borderRadius: '10px',
        }}
      >
        <span
          className='fs-3 fw-bold text-align-center'
          style={{ color: 'var(--dark)' }}
        >
          Absence of consensus
        </span>
      </div>
    );
  }

  renderButton() {
    const translations = {
      en: {
        seeResults: 'See results',
        nextSession: 'Next session',
        nextStatement: 'Move on to the next statement',
      },
      fr: {
        seeResults: 'Voir les résultats',
        nextSession: 'Session suivante',
        nextStatement: 'Passer à la prochaine assertion',
      },
    };
    const t =
      translations[
        this.state.event?.language != undefined
          ? this.state.event.language
          : 'fr'
      ];

    switch (this.state.mode) {
      case 'vote':
        return (
          <LoadingButton
            variant='outline-dark'
            onClick={this.displayResults.bind(this)}
          >
            {t.seeResults}
          </LoadingButton>
        );
      case 'session_finished':
        return (
          <LoadingButton
            variant='outline-dark'
            onClick={this.nextStatement.bind(this)}
          >
            {t.nextSession}
          </LoadingButton>
        );
      case 'results':
        return (
          <LoadingButton
            variant='outline-dark'
            onClick={this.nextStatement.bind(this)}
          >
            {t.nextStatement}
          </LoadingButton>
        );
    }
  }
  generateCircles(length) {
    const translations = {
      stronglyDisagree:
        this.state.event.langage === 'fr'
          ? 'Fortement en désaccord'
          : 'Strongly disagree',
      stronglyAgree:
        this.state.event.langage === 'fr'
          ? 'Fortement en accord'
          : 'Strongly agree',
      noOpinion:
        this.state.event.langage === 'fr' ? 'Sans opinion' : 'No opinion',
    };
    return (
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '15%',
            marginTop: '70px',
          }}
        >
          <span className='fs-4 fw-bold me-3' style={{ textAlign: 'right' }}>
            {translations.stronglyDisagree}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '70%',
          }}
        >
          <div
            className='mt-3'
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {Array.from({ length: length }, (_, index) => (
              <Spring
                key={index}
                from={{ opacity: 0, transform: 'scale(0)' }}
                to={{ opacity: 1, transform: 'scale(1)' }}
                config={{ tension: 200, friction: 20 }}
              >
                {(props) => (
                  <animated.div
                    className='fs-3 fw-bold'
                    style={{
                      ...props, // Spread the props to apply the animation
                      width: '50px',
                      height: '50px',
                      borderRadius: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'var(--light)',
                      color: 'var(--dark)',
                    }}
                  >
                    {index + 1}
                  </animated.div>
                )}
              </Spring>
            ))}
          </div>
          <Spring from={{ width: '0%' }} to={{ width: '100%' }}>
            {(props) => (
              <animated.div
                style={{
                  ...props,
                  display: 'flex',
                  marginTop: '10px',
                  height: '10px',
                  backgroundColor: 'var(--light)',
                  borderRadius: 10,
                }}
              ></animated.div>
            )}
          </Spring>
        </div>
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '15%',
            marginTop: '70px',
          }}
        >
          <span className='fs-4 fw-bold ms-3' style={{ textAlign: 'left' }}>
            {translations.stronglyAgree}
          </span>
        </div>
      </div>
    );
  }

  async nextStatement() {
    try {
      this.setState({ loading: true });
      const nextStatement = await nextStatementEvent(
        this.state.event.id,
        this.state.mode === 'session_finished' ? true : false
      );
      if (!nextStatement) {
        this.stopRequesting();
        this.setState({ loading: false, mode: 'finished' });
        return;
      }
      if (
        nextStatement.order_number === this.state.currentStatement.order_number
      ) {
        this.stopRequesting();
        this.setState({ loading: false, mode: 'session_finished' });
        return;
      }
      this.setState({ currentStatement: nextStatement, mode: 'vote' });
      this.startRequesting();
    } catch (error) {
      this.handleError(error);
    }

    this.setState({ loading: false });
  }
  async componentDidMount() {
    // Replace this URL with the correct endpoint URL
    this.setState({ loading: true });
    try {
      await isPresenterAuth();
      const event = await getEventByUrl(this.props.params.unique_str);
      const colors = await getEventColors(event.id);
      this.setState({ colors });

      if (event.status === 'finished') {
        this.setState({ event, loading: false, mode: 'finished' });
        return;
      }

      const currentStatement = await getCurrentStatement(event.id);

      if (event.pause) {
        const results = await getStatementResults(
          event.id,
          currentStatement.id
        );
        this.setState({ results });
      }
      let mode = event.pause_session
        ? 'session_finished'
        : event.pause
        ? 'results'
        : 'vote';
      if (mode === 'vote') this.startRequesting();
      this.setState({
        event,
        currentStatement,

        mode: mode,
        loading: false,
      });
    } catch (e) {
      this.handleError(e);
    }
  }

  componentWillUnmount() {
    this.stopRequesting();
  }

  render() {
    const lang = this.state.event?.langage;
    const translations = {
      en: {
        heading: 'DELPHI VOTING',
        notFound: 'Event not found',
        endOfSession: 'END OF SESSION',
        statementToVote: 'Statement to vote',
        loading: 'Loading...',
        howMuchAgree: 'How much do you agree with this statement?',
        voteOnPhone: 'Please vote on your phone!',
        resultsOfVoting: 'Results of voting',
        scores: 'scores',
        median: 'median',
        endOfVote: 'END OF THE VOTE',
        thanksForParticipation: 'THANKS FOR YOUR PARTICIPATION',
        voter: 'voter',
      },
      fr: {
        heading: 'Plateforme DELPHI',
        notFound: 'Événement non trouvé',
        endOfSession: 'FIN DE LA SESSION',
        statementToVote: 'Assertion',
        loading: 'Chargement...',
        howMuchAgree: 'Que pensez-vous de cette assertion?',
        voteOnPhone: 'Veuillez voter sur votre téléphone!',
        resultsOfVoting: 'Résultats du vote',
        scores: 'scores',
        median: 'médiane',
        endOfVote: 'FIN DU VOTE',
        thanksForParticipation: 'MERCI POUR VOTRE PARTICIPATION',
        voter: 'votant',
      },
    };
    const t = translations['fr'];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: this.state.colors?.background_color,
          overflowY: 'hidden',
        }}
      >
        <img
          src={
            this.state.event
              ? BASE_URL +
                '/events/' +
                this.state.event.id +
                '/data/presenter_background'
              : null
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
          }}
          alt=''
          style={{
            position: 'absolute', // Absolute positioning
            top: 0, // Align to the top
            left: '5%', // Align to the left
            // Full width
            height: '100%', // Full height
            objectFit: 'fill', // Forces the content to fill the container, ignoring aspect ratio
          }}
        />
        <PresenterNavBar
          backgroundColor={this.state.colors?.header_background_color}
          text={
            this.state.currentStatement
              ? `${t.heading} - ${this.state.currentStatement.session_name}`
              : t.heading
          }
        ></PresenterNavBar>

        <Container
          fluid
          className='p-3 px-5'
          style={{
            flex: 1,
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            overflowY: 'hidden', // Prevent scrolling within the main content
            position: 'relative',
          }}
        >
          <Alert show={this.state.error} variant={'danger'}>
            <Alert.Heading>{t.notFound}</Alert.Heading>
            <p>{this.state.error_message}</p>
          </Alert>
          {this.state.event &&
          this.state.currentStatement &&
          this.state.mode !== 'finished' ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                height: '100%',
              }}
            >
              <div
                className='pb-3'
                style={{
                  display: 'flex',
                  flexGrow: 1,

                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {this.state.mode === 'session_finished' ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      borderRadius: '10px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Col
                      xs={12}
                      md={6}
                      style={{
                        display: 'flex',
                        backgroundColor:
                          this.state.colors?.header_background_color,
                        height: '50%',
                        borderRadius: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        className='fs-4 fw-bold'
                        style={{ color: 'var(--light)' }}
                      >
                        {t.endOfSession}{' '}
                        {this.state.currentStatement.session_number}
                      </span>
                    </Col>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      borderRadius: '10px',
                      flexDirection: 'column',
                    }}
                  >
                    <h3
                      className='p-2'
                      style={{
                        backgroundColor: this.state.colors?.background_color,
                        borderRadius: '10px',
                      }}
                    >
                      {t.statementToVote}
                    </h3>
                    <Spring
                      key={this.state.currentStatement.id} // Add this line
                      from={{ opacity: 0, transform: 'scale(0.8)' }}
                      to={{ opacity: 1, transform: 'scale(1)' }}
                    >
                      {(props) => (
                        <animated.div
                          style={{
                            ...props,
                            display: 'flex',
                            borderRadius: '15px',
                            alignItems: 'center',
                            backgroundColor:
                              this.state.colors?.statement_background_color,
                            boxShadow: '0px 2px 20px -10px rgba(0,0,0,0.2)',
                          }}
                          className='p-3 mb-3'
                        >
                          <div
                            style={{
                              display: 'flex',
                              height: '5em',
                              aspectRatio: 1,
                              borderRadius: '50%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            className='bg-light me-3'
                          >
                            <span className='fs-2 fw-bolder'>
                              #{this.state.currentStatement.order_number}
                            </span>
                          </div>

                          <span className='fs-3 fw-bold text-light'>
                            {this.formatStatement(
                              this.state.currentStatement.statement_string
                            )}
                          </span>
                        </animated.div>
                      )}
                    </Spring>
                    {this.state.loading && (
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Spinner animation='grow' variant='primary' />
                      </div>
                    )}

                    {this.state.mode === 'vote' && !this.state.loading && (
                      <Row>
                        <Col md={2}>
                          <div></div>
                        </Col>
                        <Col md={10}>
                          <div
                            className='p-3'
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              backgroundColor:
                                this.state.colors?.background_color,
                              borderRadius: '10px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <QuestionCircleFill
                                className='me-2'
                                size={24}
                              ></QuestionCircleFill>
                              <span className='fs-4 fw-bolder'>
                                {t.howMuchAgree}
                              </span>
                              <div
                                className='ms-3 px-2'
                                style={{
                                  flexDirection: 'row',
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: 'var(--light)',
                                  alignItems: 'center',
                                  borderRadius: '10px',
                                }}
                              >
                                <PersonLinesFill size={24}></PersonLinesFill>
                                <span className='fs-4 ms-1'>
                                  {this.state.nbVotes}
                                </span>
                              </div>
                            </div>
                            {this.generateCircles(9)}
                            <div
                              className='mt-1 px-3'
                              style={{
                                backgroundColor: 'var(--light)',
                                height: '50px',
                                borderRadius: '25px',
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                className='fs-4 fw-bold'
                                style={{ textAlign: 'center' }}
                              >
                                No opinion
                              </span>
                            </div>
                            <div
                              className='mt-3'
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Spring
                                from={{
                                  transform: 'scale(0.8)',
                                  opacity: 0.4,
                                }}
                                to={{
                                  transform: 'scale(1)',
                                  opacity: 1,
                                }}
                                config={{ duration: 2000 }}
                              >
                                {(props) => (
                                  <animated.div style={props}>
                                    <PhoneFill size={36} />
                                  </animated.div>
                                )}
                              </Spring>
                              <span className='fs-4 fw ms-3'>
                                {t.voteOnPhone}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {this.state.mode === 'results' && !this.state.loading && (
                      <Row
                        style={{
                          display: 'flex',
                          flex: 1,
                          height: '100%',
                        }}
                      >
                        <Col md={2}>
                          <div></div>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            display: 'flex',
                            flex: 1,
                            height: '100%',
                          }}
                        >
                          <div
                            className='p-3'
                            style={{
                              display: 'flex',
                              flex: 1,
                              height: '100%',

                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              backgroundColor:
                                this.state.colors?.background_color,
                              borderRadius: '10px',
                            }}
                          >
                            <div
                              className='mb-3'
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <BarChartFill
                                  className='me-2'
                                  size={24}
                                ></BarChartFill>
                                <span className='fs-4 fw-bolder'>
                                  {t.resultsOfVoting}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  className='px-3 ms-3'
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: 'var(--light)',
                                    alignItems: 'center',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <PersonLinesFill
                                    className='me-2'
                                    size={24}
                                  ></PersonLinesFill>
                                  <span className='fs-4 fw-bolder'>
                                    {this.state.results.total_votes}{' '}
                                    {this.state.results.total_votes > 1
                                      ? t.voter + 's'
                                      : t.voter}
                                  </span>
                                </div>
                                {this.state.results.votes_null > 0 && (
                                  <div
                                    className='px-3 ms-3'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor: 'var(--light)',
                                      alignItems: 'center',
                                      opacity: 0.5,
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <Square className='me-2' size={24}></Square>
                                    <span className='fs-4 fw-bolder'>
                                      {this.state.results.votes_null} no opinion
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <Container
                              style={{
                                flex: 1,
                                height: '100%',
                              }}
                            >
                              <Row
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  flexDirection: 'row',
                                  height: '100%',

                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Col
                                  md={4}
                                  xs={12}
                                  className='me-3'
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <BarGraph
                                    percentageVotes={
                                      this.state.results.percentage_votes
                                    }
                                  />
                                </Col>
                                <Col
                                  md={4}
                                  xs={12}
                                  className='me-3'
                                  style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                    }}
                                  >
                                    <div
                                      className='mb-3'
                                      style={{
                                        display: 'flex',

                                        height: '75px',
                                        width: '75px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'var(--light)',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <span className='fs-4 fw-bold'>
                                        {this.state.results.percentage_votes_gte_7.toFixed(
                                          0
                                        )}{' '}
                                        %
                                      </span>
                                    </div>
                                    <hr
                                      style={{ width: '100%', height: '1px' }}
                                    ></hr>
                                    <div
                                      style={{
                                        display: 'flex',
                                        height: '75px',
                                        width: '75px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '10px',
                                        backgroundColor: 'var(--light)',
                                      }}
                                    >
                                      <span className='fs-4 fw-bold'>
                                        {this.state.results.median.toFixed(1)}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',

                                      flex: 1,
                                    }}
                                  >
                                    <div
                                      className='mb-3'
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',

                                        alignItems: 'center',
                                        flex: 1,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          height: '75px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderRadius: '10px',
                                        }}
                                      >
                                        <div
                                          className='border p-1'
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderRadius: '10px',
                                            marginRight: '10px',
                                          }}
                                        >
                                          {Array.from(
                                            { length: 3 },
                                            (_, index) => (
                                              <div
                                                className='fs-4 fw-bold m-1'
                                                key={index}
                                                style={{
                                                  width: '26px',
                                                  height: '26px',
                                                  borderRadius: '13px',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  color: 'var(--dark)',
                                                  backgroundColor:
                                                    'var(--light)',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                {index + 7}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>

                                      <div>
                                        <span className='fs-4 fw-bold'>
                                          scores
                                        </span>
                                      </div>
                                    </div>
                                    <hr
                                      style={{ width: '100%', height: '1px' }}
                                    ></hr>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        flex: 1,
                                      }}
                                    >
                                      <span className='fs-4 fw-bold'>
                                        median
                                      </span>
                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  md={4}
                                  xs={12}
                                  style={{
                                    display: 'flex',
                                    flex: 1,
                                    height: '100px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {this.renderConsensus()}
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
                {this.renderButton()}
              </div>
            </div>
          ) : this.state.loading ? (
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner animation='grow' variant='primary' />
            </div>
          ) : !this.state.error ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                height: '100%',
              }}
            >
              <div
                className='pb-3'
                style={{
                  display: 'flex',
                  flexGrow: 1,

                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Col
                    xs={12}
                    md={6}
                    style={{
                      display: 'flex',
                      backgroundColor:
                        this.state.colors?.header_background_color,
                      height: '50%',
                      borderRadius: '10px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      className='fs-4 fw-bold text-align-center'
                      style={{ color: 'var(--light)' }}
                    >
                      END OF THE VOTE
                    </span>
                    <span
                      className='fs-4 fw-bold text-align-center'
                      style={{ color: 'var(--light)' }}
                    >
                      THANKS FOR YOUR PARTICIPATION
                    </span>
                  </Col>
                </div>
              </div>
            </div>
          ) : null}
        </Container>
        {this.state.mode !== 'finished' && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                position: 'sticky',
                bottom: 0,
                marginBottom: '-50px',
                height: '20vh',
                width: '20vh',
                backgroundColor: this.state.colors?.header_background_color,
                zIndex: 100,
                borderRadius: '10px',
                marginRight: '5%',
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src={
                  this.state.event
                    ? BASE_URL +
                      '/events/' +
                      this.state.event.id +
                      '/voter_qrcode'
                    : null
                }
              ></img>
            </div>
          </div>
        )}

        <PresenterFooter
          backgroundColor={this.state.colors?.header_background_color}
          img={
            this.state.event
              ? BASE_URL + '/events/' + this.state.event.id + '/data/logo'
              : null
          }
        ></PresenterFooter>
      </div>
    );
  }
}

export default withRouter(withTheme(PresenterSession));
